import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { practitionerData } from './index';

const Section4 = () => (
  <div className="section-4">
    <div className="header">
      <h1>
        <FormattedMessage id="about.esteemedPractitioners" defaultMessage="Our esteemed Care Providers" />
      </h1>
      <p>
        <FormattedMessage id="about.meetAFew" defaultMessage="Meet just a few of the professionals who work hard to help you operate at your best." />
      </p>
    </div>
    {

      }
    <div className="care-providers">
      {_.map(practitionerData, ({
        id, name, image, degree,
      }) => (
        <div key={id} className="care-provider">
          <img src={image} className="profile" alt="Portrait" />
          <p className="name">{name}</p>
          <p className="title">{degree}</p>
        </div>
      ))}
    </div>
    {/* <div className="button">
        <button className="dark-blue-button">
          <FormattedMessage
            id="about.learnMore"
            defaultMessage="Learn more about our Care Providers"
          />
        </button>
      </div> */}
  </div>
);


export default Section4;
