import React from 'react';
import _ from 'lodash';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { aboutData } from './index';
import section1animation from '../../images/about/animations/section-1-animation.png';

const Section1 = () => (
  <div className="section-1">
    <div className="header">
      <h5>
        <FormattedMessage id="about.whoWeAre" defaultMessage="Who We Are" />
      </h5>
      <h1>
        <FormattedMessage id="about.inkblotPhilosophy" defaultMessage="The Inkblot Philosophy" />
      </h1>
      <img src={section1animation} alt="About Inkblot" />
    </div>
    <div className="grey-box">
      {_.map(aboutData, data => (
        <p key={data.id} style={{ maxWidth: data.maxWidth }}>
          <FormattedHTMLMessage
            tagName="p"
            id={data.locale}
            defaultMessage={data.text}
          />
        </p>
      ))}
      <FormattedHTMLMessage
        tagName="h2"
        id="about.reinventingSupport"
        defaultMessage="We are reinventing supports for mental health. <span class='h2-span'>Join us.</span>"
      />
    </div>
  </div>
);

export default Section1;
